import EqualizerIcon from '@mui/icons-material/Equalizer';
import { ToolCard } from '../../DomainPage/PowerBiReports/ToolCard';
import { useState } from 'react';
import InputData from '../../admin/ForecastDeliveriesManager/InputData';
import { useNavigate } from 'react-router';

export default function ForecastDeliveries() {


  // const [open, setOpen] = useState(false);
  const navigate = useNavigate();


  return (
    <>
      <ToolCard
        label={'Forecast & Deliveries'}
        icon={<EqualizerIcon sx={{
          width: 70,
          height: 70,
          padding: '10px',
        }} />}
        color={'rgb(25, 118, 210)'}
        onClick={() => {
          navigate({
            pathname: `/domains/tools/forecastDeliveries`,
          });
        }}
      />
    </>
  );
}
