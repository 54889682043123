import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useSearchParams } from 'react-router-dom';
import apiClient from '../../../../services/apiClient';
import FilterDialog from '../FilterDialog/FilterDialog';

interface FilterCell {
  name: string;
  id: number;
}

export default function RecordFilterSelect({
  source,
  label,
  id,
  selected,
  onRecordChange,
  onFilterCancel,
  onFilterApply,
  disabled,
  displaySelectedNumber = true,
  productCatalogRecords = [],
  isProductCatalog = false,
  onFilterClear = () => { },
  onModalOpen = () => { },
  forForecastDeliveries = false,
  recordsSource = [],
}: any) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState<Array<FilterCell>>([]);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (open) {
      if (isProductCatalog) {
        setLoading(false);
        setRecords(
          productCatalogRecords.map((record: any, index: any) => ({
            id: index,
            name: record,
          })),
        );
      } else if (forForecastDeliveries && recordsSource.length > 0) {
        setLoading(false);
        setRecords(
          recordsSource
        );
      } else {
        setLoading(true);
        const navDomain = searchParams.get('navDomain')?.split('-');
        apiClient
          .get(
            `${source === 'productCatalogs'
              ? '/productCatalog'
              : source === 'reports'
                ? '/reports'
                : source
            }`,
            {
              params: {
                contractDomain:
                  navDomain?.length === 1 ? navDomain[0] : undefined,
                contractCategory:
                  navDomain?.length === 2 || navDomain?.length === 3
                    ? navDomain[1]
                    : undefined,
                hasContracts: source === 'productCatalogs' ? undefined : true,
                toCollect: source === 'natcos' ? true : false,
              },
            },
          )
          .then((response) => {
            setRecords(
              response.data.records.map((record: any) => ({
                id: record.id,
                name: source === 'natcos' ? record.code : (record.name ? record.name : record.email),
              })),
            );

            setLoading(false);
          })
          .catch((error) => {
            //TODO: handle error response
            console.log(error);
            setLoading(false);
          });
      }
    }
  }, [searchParams, source, open, isProductCatalog]);

  const handleClickOpen = () => {
    onModalOpen();
    setOpen(true);
  };

  const handleRecordChange = (checked: any, record: any, replaceAll = false) => {
    // console.log("---checked", checked, "---record", record, "replaceAll", replaceAll);
    onRecordChange(source, checked, record, id, label, replaceAll);
  };

  const handleFilterCancel = () => {
    onFilterCancel(source, id);
    setOpen(false);
  };

  const handleFilterApply = (source: any) => {
    onFilterApply(source, id, label);
    setOpen(false);
  };

  const handleFilterClear = () => {
    onFilterClear(source, id, label);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth
        sx={{ height: 40 }}
        disabled={disabled}
      >
        {label} {!forForecastDeliveries && (selected?.length || 'All')}
      </Button>
      <FilterDialog
        open={open}
        handleFilterCancel={handleFilterCancel}
        label={label}
        selected={selected}
        loading={loading}
        records={records}
        handleRecordChange={handleRecordChange}
        handleFilterApply={handleFilterApply}
        isProductCatalog={isProductCatalog}
        handleFilterClear={handleFilterClear}
        forForecastDeliveries={forForecastDeliveries}
      ></FilterDialog>
    </div>
  );
}
